const PROJECTS = [
    {
        title: 'VitaTrack',
        description:  'VitaTrack is a medical software for analyzing the contents of foodstuffs using LLMs and image recognition. Simply take a photograph, and have all the relevant food data anaylzed and added for your reflection. It also supports the ability to see cumulative food statistics, get alternative food suggestions, and provide general dietary goals.',
        role: 'Operated as the Scrum Master as well as the Backend/API/Deployment lead. Was responsible for ensuring security and reliability, as well as easy redeployment and testing.',
        time: '09/2024 - 12/2024',
        links: [
            ['Git', 'https://github.com/RudolphSedlin/VitaTrack'],
        ],
        images: ['/images/VitaTrackHomePage.png', '/images/VitaTrackCameraView.png'],
    },

    {
        title: 'Anti-Copy-Paster-Python',
        description:  'Anti-Copy-Paster-Python is a plugin for Pycharm that tracks the copying and pasting carried out by the developer and suggests extracting duplicates into a new method as soon as they are introduced in the code.',
        role: 'Implemented new Tensorflow prediction models, upgraded refactoring heuristics and preprocessing, and upgraded the user settings interface.',
        time: '08/2023 - 05/2024',
        links: [
            ['Website', 'https://se4airesearch.github.io/AntiCopyPaster_Python_Website_Fall2023/'],
            ['Git', 'https://github.com/SE4AIResearch/AntiCopyPaster_Python_Fall2023'],
        ],
       images: ['/images/ACP.gif', '/images/ACPsettings.png', '/images/advancedsettings.png'],
    },

    {
        title: 'Ducks In A Row',
        description: 'A website used for managing tasks and creating schedules.',
        role: 'Developed backend and database services for Ducks In A Row',
        time: '10/2023 - 12/2023',
        links: [
            ['Git', 'https://github.com/hamc24/CS546_fall23_group_17_FP'],
        ],
        images: [],
    },

    {
        title: 'Zen Alchemist 8086',
        description:  'Zen Alchemist 8086 is an in-order sequential processor design with 16-bit instruction and data words and word-adressed memory. It provides 16 general purpose 16-bit integer registers, arithmetic operations on both register and immediate values including addition, multiplication, subtraction, and division, load and store, conditional and unconditional label branching, and halting.',
        role: 'Implemented in entirety, with the intent to exceed the original x86 spec for things like register files.',
        time: '10/2022 - 12/2022',
        links: [],
        images: ['/images/ZenAlchemist8086.png', '/images/Assembly.png'],
    },

    {
        title: 'Zenpower3',
        description:  'Zenpower3 is a Linux kernel driver for reading temperature, voltage(SVI2), current(SVI2) and power(SVI2) for AMD Zen family CPUs, now with Zen 3 support!',
        role: 'Contributed hardware specifications, test data, benchmarking, and diagnostics to the development of Zenpower3 for reporting hardware sensor data on AMD Cezanne and Vermeer processors in Linux',
        time: '08/2021 - 10/2021',
        links: [
            ['Git', 'https://git.exozy.me/a/zenpower3'],
        ],
        images: ['/images/Zenpower.png'],
    },
]

export default PROJECTS;
